import { apiSlice } from "../api/apiSlice";

export const peripheralAssignApi = apiSlice.injectEndpoints({
  tagTypes: ["assign"],
  endpoints: (builder) => ({
    getPeripheralAssignList: builder.query({
      query: () => "/apiV2/ac/peripheral-assign-list",
      providesTags: ["assign"],
    }),
    assignPeripheral: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/peripheral-assign-save",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["assign"],
    }),
    updateAssignedPeripheral: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/peripheral-assign-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["assign"],
    }),
  }),
});

export const {
  useGetPeripheralAssignListQuery,
  useAssignPeripheralMutation,
  useUpdateAssignedPeripheralMutation,
} = peripheralAssignApi;
