import React, { useState, useEffect } from "react";
// import "./App.css";
import layout from "./assets/layout.jpg";

const gridSize = 20;
const gridWidth = 50;
const gridHeight = 50;

function AcSensorMap() {
  const initialSensors = [
    { id: 1, x: 100, y: 100, temp: 20 },
    { id: 2, x: 300, y: 200, temp: 35 },
    { id: 3, x: 400, y: 300, temp: 45 },
  ];

  const [sensors, setSensors] = useState(initialSensors);
  const [draggingSensor, setDraggingSensor] = useState(null);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    // Update heatmap only when sensors change
  }, [sensors]);

  const handleMouseDown = (index, e) => {
    setDraggingSensor(index);
    setStartPos({
      x: e.clientX - sensors[index].x,
      y: e.clientY - sensors[index].y,
    });
  };

  const handleMouseMove = (e) => {
    if (draggingSensor === null) return;

    const newX = e.clientX - startPos.x;
    const newY = e.clientY - startPos.y;

    // Move the dragged sensor
    setSensors((prevSensors) =>
      prevSensors.map((sensor, i) =>
        i === draggingSensor ? { ...sensor, x: newX, y: newY } : sensor
      )
    );
  };

  const handleMouseUp = () => {
    setDraggingSensor(null); // Stop dragging
  };

  const getHeatColor = (temp) => {
    if (temp <= 20) return "rgba(0, 0, 255, 0.3)"; // Cool: Blue
    if (temp <= 35) return "rgba(0, 255, 0, 0.3)"; // Medium: Green
    return "rgba(255, 0, 0, 0.3)"; // Hot: Red
  };

  return (
    <div
      className="App"
      style={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${layout})`, // Add your image path here
        backgroundSize: "cover", // Ensures the background fits the container
        backgroundPosition: "center",
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {/* Circular Heatmap Effects */}
      {sensors.map((sensor) => (
        <div
          key={`heatmap-${sensor.id}`}
          style={{
            position: "absolute",
            top: `${sensor.y - 150}px`,
            left: `${sensor.x - 150}px`,
            width: "300px",
            height: "300px",
            borderRadius: "50%",
            background: `radial-gradient(circle, ${getHeatColor(
              sensor.temp
            )} 0%, rgba(255, 255, 255, 0) 100%)`,
            pointerEvents: "none",
          }}
        ></div>
      ))}

      {/* Draggable Sensors */}
      {sensors.map((sensor, index) => (
        <div
          key={sensor.id}
          className="sensor-div"
          onMouseDown={(e) => handleMouseDown(index, e)}
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: "lightgray",
            border: "2px solid black",
            borderRadius: "50%",
            position: "absolute",
            left: `${sensor.x}px`,
            top: `${sensor.y}px`,
            cursor: "grab",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {sensor.temp}°C
        </div>
      ))}
    </div>
  );
}

export default AcSensorMap;
