import { apiSlice } from "../api/apiSlice";

export const acUserBranchAssignApi = apiSlice.injectEndpoints({
  tagTypes: ["branch-assign"],
  endpoints: (builder) => ({
    getAcBranchAssignList: builder.query({
      query: () => "/apiV2/ac/user-branch-assign-list",
      providesTags: ["branch-assign"],
    }),
    createAcBranchAssign: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/user-assign-save",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["branch-assign"],
    }),
    updateAcBranchAssign: builder.mutation({
      query: (data) => ({
        url: "/apiV2/ac/user-assign-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["branch-assign"],
    }),
  }),
});

export const {
  useGetAcBranchAssignListQuery,
  useCreateAcBranchAssignMutation,
  useUpdateAcBranchAssignMutation,
} = acUserBranchAssignApi;
