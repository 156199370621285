import React, { useState, useEffect } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";

import Swal from "sweetalert2";
import useTitle from "../../hooks/useTitle";

import { useAeratorOnOffMutation } from "../../features/pksfw2-motor-control/Pksfw2MotorControlApi";
import { usePksfw2MotorOnOffMutation } from "../../features/pksfw2-motor-control/Pksfw2MotorControlApi";
import { useGetPksfw2MotorStatusQuery } from "../../features/pksfw2-motor-control/Pksfw2MotorControlApi";
import { usePksfw2AutoManualSwitchMutation } from "../../features/pksfw2-motor-control/Pksfw2MotorControlApi";

import styles from "./pksfw.module.css";

function Pksfw2MotorControl() {
  useTitle("Motor Control");
  const theme = useContext(ThemeContext);

  const [onOffAeratorServerStatus, setOnOffAeratorServerStatus] =
    useState(false);

  const [onOffMotorServerStatus, setOnOffMotorServerStatus] = useState(false);
  const [autoManualServerStatus, setAutoManualServerStatus] = useState(false);

  const [aeratorOnOff] = useAeratorOnOffMutation();
  const [pksfw2MotorOnOff] = usePksfw2MotorOnOffMutation();

  const [pksfw2AutoManualSwitch] = usePksfw2AutoManualSwitchMutation();

  const { data: motorStatus, isLoading } = useGetPksfw2MotorStatusQuery();

  // const { data: sensorStatus, isLoading } = useGetSensorSwitchStatusQuery();

  // useEffect(() => {
  //   if (!isLoading) {
  //     const motorStatusData = motorStatus;

  //     if (motorStatusData) {
  //       if (motorStatusData?.motor_status == "0") {
  //         setOnOffMotorServerStatus(false);
  //       } else {
  //         setOnOffMotorServerStatus(true);
  //       }
  //     }
  //   }
  // }, [isLoading, motorStatus]);

  // useEffect(() => {
  //   if (!isLoading) {
  //     const motorStatusData = motorStatus;

  //     if (motorStatusData) {
  //       if (motorStatusData?.aerator_status == "0") {
  //         setOnOffAeratorServerStatus(false);
  //       } else {
  //         setOnOffAeratorServerStatus(true);
  //       }
  //     }
  //   }
  // }, [isLoading, motorStatus]);

  useEffect(() => {
    if (!isLoading && motorStatus) {
      const { motor_status, aerator_status, device_status } = motorStatus;

      setOnOffMotorServerStatus(motor_status === "1");
      setOnOffAeratorServerStatus(aerator_status === "1");
      setAutoManualServerStatus(device_status === "manual");
    }
  }, [isLoading, motorStatus]);

  const aeratorStatusChangeHandler = (e) => {
    let motorStatus = e.target.checked ? "1" : "0"; //0=false or 1=true
    // setOnOffAeratorServerStatus(!onOffAeratorServerStatus);
    Swal.fire({
      title: "Processing...",
      text: "Please wait",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    aeratorOnOff({
      switch: motorStatus,
    })
      .unwrap()
      .then((payload) => {
        // if (motorStatus == "1") {
        //   Swal.fire(
        //     payload.message,
        //     // "Aerator Status Switched to ON",
        //     "",
        //     "success"
        //   );
        // } else {
        //   Swal.fire(
        //     payload.message,
        //     // "Aerator Status Switched to OFF",
        //     "",
        //     "success"
        //   );
        // }
        if (payload.code === 500) {
          Swal.fire(payload.message, "", "error");
        } else {
          Swal.fire(payload.message, "", "success");
          setOnOffAeratorServerStatus(!onOffAeratorServerStatus);
        }
      })
      .catch((error) =>
        Swal.fire({
          icon: "error",
          title: error.status,
          text: "Something went wrong!",
        })
      );
  };

  const motorStatusChangeHandler = (e) => {
    let motorStatus = e.target.checked ? "1" : "0"; //0=false or 1=true
    // setOnOffMotorServerStatus(!onOffMotorServerStatus);
    Swal.fire({
      title: "Processing...",
      text: "Please wait",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    pksfw2MotorOnOff({
      switch: motorStatus,
    })
      .unwrap()
      .then((payload) => {
        // if (motorStatus == "1") {
        //   Swal.fire(payload.message, "", "success");
        // } else {
        //   Swal.fire(payload.message, "", "success");
        // }
        if (payload.code === 500) {
          Swal.fire(payload.message, "", "error");
        } else {
          Swal.fire(payload.message, "", "success");
          setOnOffMotorServerStatus(!onOffMotorServerStatus);
        }
      })
      .catch((error) =>
        Swal.fire({
          icon: "error",
          title: error.status,
          text: "Something went wrong!",
        })
      );
  };

  const autoManualChangeHandler = (e) => {
    const toggleStatus = e.target.checked ? "1" : "0"; //0=false or 1=true
    // setAutoManualServerStatus(!autoManualServerStatus);
    Swal.fire({
      title: "Processing...",
      text: "Please wait",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    pksfw2AutoManualSwitch({
      switch: toggleStatus,
    })
      .unwrap()
      .then((payload) => {
        // if (toggleStatus === "1") {
        //   Swal.fire(
        //     payload.message,
        //     // "Toggle Status Switched to Manual",
        //     "",
        //     "success"
        //   );
        // } else {
        //   Swal.fire(
        //     payload.message,
        //     // "Toggle Status Switched to Auto",
        //     "",
        //     "success"
        //   );
        // }
        if (payload.code === 500) {
          Swal.fire(payload.message, "", "error");
        } else {
          Swal.fire(payload.message, "", "success");
          setAutoManualServerStatus(!autoManualServerStatus);
        }
      })
      .catch((error) =>
        Swal.fire({
          icon: "error",
          title: error.status,
          text: "Something went wrong!",
        })
      );
  };

  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        <div className="row justify-content-center align-items-center">
          <div class="col-md-4">
            <div className="room-card card theme-background">
              <h4 className="room-name">Auto/Manual Switch</h4>
              <div
                className="motor-on-off-switch"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "50px",
                }}
              >
                <h5 className={`off-label theme-text`}>Auto</h5>
                <div className="only-switch">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={autoManualChangeHandler}
                      checked={autoManualServerStatus}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <h5 className={`theme-text`}>Manual</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div class="col-md-4">
            <div className="room-card card theme-background">
              <h4 className="room-name">Aerator Switch</h4>

              <div
                className="motor-on-off-switch"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "50px",
                }}
              >
                <h5
                  className={`off-label theme-text ${
                    autoManualServerStatus === false
                      ? "text-decoration-line-through"
                      : ""
                  }`}
                >
                  Off
                </h5>
                <div className="only-switch">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={aeratorStatusChangeHandler}
                      checked={onOffAeratorServerStatus}
                      disabled={!autoManualServerStatus}
                    />
                    <span
                      className="slider round"
                      style={{ opacity: autoManualServerStatus ? 1 : 0.5 }}
                    ></span>
                  </label>
                </div>
                <h5
                  className={`theme-text ${
                    autoManualServerStatus === false
                      ? "text-decoration-line-through"
                      : ""
                  }`}
                >
                  On
                </h5>
              </div>
            </div>
          </div>
          <div class={`col-md-4 `}>
            <div className="room-card card theme-background">
              <h4 className="room-name">Motor Switch</h4>

              <div
                className="motor-on-off-switch"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "50px",
                }}
              >
                <h5
                  className={`off-label theme-text ${
                    autoManualServerStatus === false
                      ? "text-decoration-line-through"
                      : ""
                  }`}
                >
                  Off
                </h5>
                <div className="only-switch">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={motorStatusChangeHandler}
                      checked={onOffMotorServerStatus}
                      disabled={!autoManualServerStatus}
                    />
                    <span
                      className="slider round"
                      style={{ opacity: autoManualServerStatus ? 1 : 0.5 }}
                    ></span>
                  </label>
                </div>
                <h5
                  className={`theme-text ${
                    autoManualServerStatus === false
                      ? "text-decoration-line-through"
                      : ""
                  }`}
                >
                  On
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pksfw2MotorControl;
